import { Centrifuge, Subscription } from 'centrifuge';
import Event from '@/libs/centrifuge/Event';

/// event:client-#chanel client publish
/// event:name server publish
// struct { event:name,data:json,chanel:name}

export default class {
    /** @type {Subscription} */
    subscription = null;
    #handlers = {};

    constructor(subscription) {
        this.subscription = subscription;
        this.subscription.on('publication', (event) => {
            if (event.is_client) {
                this.#handlers[event.event].forEach((cb) => cb(event));
                return;
            }
            this.#handlers[event.data.event].forEach((cb) => cb(event.data));
        });
        this.subscription.subscribe();
    }

    listen(event, cb) {
        event = new Event(event).toString();
        if (!this.#handlers[event]) {
            this.#handlers[event] = [];
        }

        this.#handlers[event].push(cb);

        return this;
    }

    unsubscribe() {
        this.subscription.unsubscribe();
        this.#handlers = {};
    }

    listenForWhisper(event, cb) {
        if (!this.#handlers[event]) {
            this.#handlers[event] = [];
        }

        this.#handlers[event].push(cb);

        return this;
    }

    whisper(event, data) {
        data.event = event;
        data.is_client = true;
        this.subscription.publish(data);
    }
}
